import React from "react";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft, faSignOut } from "@fortawesome/pro-regular-svg-icons";

import * as workspaces from "@/utils/workspaces";
import { Box, UserAvatar, Button } from "@/components";
import MainMenu from "@/features/menu/components/MainMenu";
import { useMe } from "@/data/auth/queries";
import { useCreateWorkspace } from "@/data/workspaces/mutations";
import { logoutUser } from "@/_legacy/API/documentsAPI";
import queryClient from "@/singletons/queryClient";

import "./CreateWorkspacePage.styles.scss";

/**
 * Page prompting the user to create a new workspace.
 */
const CreateWorkspacePage = () => {
  const router = useRouter();
  const userQuery = useMe();
  const { mutateAsync: createWorkspace } = useCreateWorkspace();

  const handleLogoutClick = () => {
    logoutUser()
      .then(() => {
        queryClient.clear();
        window.location.href = "/login";
      })
      .catch(err => console.log(err));
  };

  const handleCreateWorkspaceClick = async () => {
    const createdWorkspace = await createWorkspace({
      name: workspaces.DEFAULT_WORKSPACE_NAME,
      icon: workspaces.getRandomWorkspaceColor(),
    });

    router.push({
      pathname: "/[workspaceId]",
      query: { workspaceId: createdWorkspace.id },
    });
  };

  return (
    <Box className="CreateWorkspacePage">
      <MainMenu.Root>
        <MainMenu.LeftCol>
          <MainMenu.Workspaces>
            <MainMenu.WorkspacesAddButton
              onClick={handleCreateWorkspaceClick}
            />
          </MainMenu.Workspaces>
        </MainMenu.LeftCol>
      </MainMenu.Root>
      <Box
        className="CreateWorkspacePage__ButtonHint"
        onClick={handleCreateWorkspaceClick}
      >
        <FontAwesomeIcon className="mr-2" icon={faLongArrowLeft} />
        Setup your first workspace!
      </Box>
      <Box className="CreateWorkspacePage__TopRight">
        <Button className="mr-8" variant="Tertiary" onClick={handleLogoutClick}>
          <FontAwesomeIcon icon={faSignOut} className="mr-2" />
          Logout
        </Button>
        <UserAvatar user={userQuery.data} />
      </Box>
    </Box>
  );
};

export default CreateWorkspacePage;
